function NavBar() {
  return (
    <div className="nav-container">
      <div className="title-area">Kristopher Dunham</div>

      <div className="nav-area">
        <a href="/#home-section" className="nav-button">Home</a>
        <a href="/#projects-section" className="nav-button">Projects</a>
        <a href="/#about-section" className="nav-button">About</a>
      </div>
    </div>
  );
}

export default NavBar;