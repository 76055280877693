import CurrentCard from "./CurrentCard"

function CurrentProjects({title, data,}){

    return(
        <div className="current-project-container">
             
        <div className="title-bar">
        <div className="title-bar-text">{title}</div>
        </div>
        <div className="current-card-container">
        {data.slice(0, 2).map((card, index) => (
            <CurrentCard key={card.index} data={card} index={index} />
          ))}
        </div>
        </div>
    )
}
export default CurrentProjects;