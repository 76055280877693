import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import Hero from "./Hero";
import About from './About';
import Prompt from './Prompt';
import CurrentProjects from "./CurrentProjects";
import Projects from './Projects';
import Footer from './Footer';
function Home() {
 
  const backgroundImageRef = useRef(null);
  const { hash, state } = useLocation();

  const [cardsData, setCardData] = useState([]);
  useEffect(() => {
      fetch("./data/json/home_cards.json")
        .then((response) => response.json())
        .then((data) => setCardData(data))
        .catch((error) => console.log(error));
    }, []);

  useEffect(() => {
    if (state && state.from === 'projects' && hash === '#about-section') {
      const element = document.getElementById('about-section');
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth' // Optional, add smooth scrolling
        });
      }
    }
  }, [hash, state]);

  useEffect(() => {
    const onScroll = () => {
      let scrollPos = window.scrollY;
      let maxHeight = document.body.scrollHeight - window.innerHeight;
      let opacity = 1 - ((scrollPos * 3) / maxHeight);
      if (opacity < 0) opacity = 0; // make sure opacity doesn't go negative
      if (backgroundImageRef.current) {
        backgroundImageRef.current.style.opacity = opacity;
      }
    };

    window.addEventListener('scroll', onScroll);

    // cleanup function
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []); // only run once on mount


  console.log("loaded data");

  return (
    <div className="home-container">
   
      <Hero />
    <CurrentProjects data={cardsData} title='Current Projects'/>
    <About/>
    <Prompt title="AI Prompting"/>
    <Projects data={cardsData} title='All Projects'/>
    <Footer/>
    
    </div>
  );
}
export default Home;
