import FadeBackground from "./FadeBackground";
import PromptCard from "./PromptCard";

function Prompt({title}) {
  const promptData = [
    {
      title: "ChatGPT",
      image: "../images/open_ai_thumb1.png",
      modal: "",
      fileName: "../data/markdown/chatgpt_prompt_text.md",
    },
    {
      title: "MidJourney",
      image: "../images/midjourney_thumb1.png",
      modal: "",
      fileName: "../data/markdown/mj_prompt_text.md",
    },
  ];

  return (
    <div className="prompt-container">
    <FadeBackground backgroundClass='fade-background' imageFile="../images/black&white_ai_bk2.png" altText="project background" />
    <div className="title-bar">
        <div className="title-bar-text">{title}</div>
      </div>
      {promptData.map((data, index) => (
        <PromptCard key={index} data={data} fileName={data.fileName} />
      ))}
    </div>
  );
}

export default Prompt;
;
