function SoftwareCard(){
    return (
        <div className="software-container">
        <div className="software-code">
        <h3>Code</h3>
        <ul>
        <li>HTML</li>
        <li>CSS</li>
        <li>JavaScript</li>
        <li>Git</li>
        <li>SQL</li>
        <li>Apple {'][e'} Basic</li>
        </ul>
        </div>
        <div className="software-creative">
        <h3>Creative</h3>
        <ul>
        <li>Adobe Illustrator</li>
        <li>Adobe Photoshop</li>
        <li>Adobe Indesign</li>
        <li>Maya</li>
        <li>Audacity</li>
        <li>WonderShare</li>
        </ul>
        
        </div>
        </div>
    )
}
export default SoftwareCard;