function ProfileCard() {
  return (
    <div className="profile-container">
      <img
        src="../images/card_one.png"
        alt="profile pic"
        className="profile-pic"
      />
      <div className="profile-title">
        <h2>Kris Dunham</h2>
        <h3>Coder/Designer</h3>
      </div>
      <div className="profile-text">
        <h4>Artist, Animator, Author, Adventurer, Curious and Creating.</h4>
        <p>
        Story is the driving force in my work, and the transition to web development has been nothing short of amazing. I've discovered that so much of the web development process aligns perfectly with what I love about creating stories and art.
          <p>
          Curiosity and discovery has driven me forward in every project I have done. Each challenge is an exciting opportunity for innovation, and every new innovation becomes a powerful tool for expression.
          </p>
          <p>
          Inspired by creative challenges, my desire is to craft digital experiences that bring stories to life, leaving a lasting impact.
          </p>
         <p> What is your story... maybe I can help tell it.</p>
        </p>
      </div>
    </div>
  );
}
export default ProfileCard;
