import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';


function SkillCard({ title, fileName }) {
    const [markdownContent, setMarkdownContent] = useState('');
  
    useEffect(() => {
      const fetchMarkdownContent = async () => {
        try {
          const response = await fetch(fileName);
          const markdownText = await response.text();
          setMarkdownContent(markdownText);
        } catch (error) {
          console.error('Error fetching markdown content:', error);
        }
      };
  
      fetchMarkdownContent();
    }, [fileName]);
  
    return (
      <div className="skill-card">
        <div className="skill-card-title">{title}</div>
        <div className="skill-card-markdown">
          <ReactMarkdown>{markdownContent}</ReactMarkdown>
        </div>
      </div>
    );
  }
  
  export default SkillCard;
  