
import React, { useEffect, useRef, useState } from "react";

function FadeBackground({backgroundClass,imageFile,altText}) {


  const backgroundImageRef = useRef(null);
  const [grayscale, setGrayscale] = useState(1); 

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // intersectionRatio is a value between 0 and 1
        const ratio = entries[0].intersectionRatio;

        // Calculate grayscale value based on intersection ratio
        const newGrayscale = Math.round(ratio * 50);

        setGrayscale(newGrayscale);
      },
      {
        threshold: [
          0,
          0.1,
          0.2,
          0.3,
          0.4,
          0.5,
          0.6,
          0.7,
          0.8,
          0.9,
          1,
        ], // Trigger callback at these thresholds
      }
    );

    if (backgroundImageRef.current) {
      observer.observe(backgroundImageRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
      <div>
       
    <img  style={{ opacity: grayscale / 30 }}
    className={backgroundClass}
    ref={backgroundImageRef} src={imageFile} alt={altText} />

        </div>
  );
}

export default FadeBackground;
;