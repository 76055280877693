import FadeBackground from "./FadeBackground";

function Hero(){
    return(
        <div  className="hero-container">
            <FadeBackground backgroundClass='fade-background-lower' imageFile="../images/black&white_cloud_bk3.png" altText="project background" />
        <div className="hero-intro-area">
        Hi, I'm <h1>Kristopher Dunham</h1>, a
        <br /></div>
        <div className="hero-title-area"> Front<span className="long-dash">-</span>End Developer</div>
        <div className="hero-text-area">Focused in React, JavaScript and AI. Background in Art and Design. Product of the 80's. Always Curious and Creating.</div>
        </div>
        
    )
}

export default Hero;