import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";

function PromptCard({ data, index, fileName }) {
  const [isVisible, setIsVisible] = useState(false);
  const [grayscale, setGrayscale] = useState(100);
  const cardRef = useRef(null);
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    const fetchMarkdownContent = async () => {
      try {
        const response = await fetch(fileName);
        const markdownText = await response.text();
        setMarkdownContent(markdownText);
      } catch (error) {
        console.error("Error fetching markdown content:", error);
      }
    };

    fetchMarkdownContent();
  }, [fileName]);

  useEffect(() => {
    const observerPrompt = new IntersectionObserver(
      (entries) => {
        // intersectionRatio is a value between 0 and 1
        const ratio = entries[0].intersectionRatio;

        // Calculate grayscale value based on intersection ratio
        const newGrayscale = Math.round((1 - ratio) * 100);

        setGrayscale(newGrayscale);

        if (ratio > 0.3) {
          setIsVisible(true);
          console.log("Slide-in class added");
        }
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1], // Trigger callback at these thresholds
      }
    );

    if (cardRef.current) {
      observerPrompt.observe(cardRef.current);
    }

    return () => observerPrompt.disconnect();
  }, []);

  return (
    <div
      ref={cardRef}
      className={`prompt-card ${isVisible ? "slide-in-bottom" : ""}`}
    >
      <div className="prompt-card-title">
        <h3>{data.name}</h3>
      </div>

      <div style={{ filter: `grayscale(${grayscale}%)` }}>
        <img className="prompt-card-image" src={data.image} alt={data.name} />
      </div>
      <div className="prompt-card-title">{data.title}</div>
      <div className="prompt-card-text">
        {" "}
        <ReactMarkdown>{markdownContent}</ReactMarkdown>
      </div>
    </div>
  );
}

export default PromptCard;
