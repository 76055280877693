import HomeCard from "./HomeCard";
import FadeBackground from "./FadeBackground";

function Projects({ data, title }) {
  return (
    <div id="projects-section" className="project-container">
      <div className="title-bar">
        <div className="title-bar-text">{title}</div>
      </div>
      <FadeBackground
        backgroundClass="fade-background"
        imageFile="../images/card_one.png"
        altText="project background"
      />
      {data.map((card, index) => (
        <HomeCard key={card.index} data={card} index={index} />
      ))}
    </div>
  );
}
export default Projects;
