import React from "react";
import ProfileCard from "./ProfileCard";
import SkillCard from "./SkillCard";
import SoftwareCard from "./SoftwareCard";
import FadeBackground from "./FadeBackground";

function About() {
  const skillsData = [
    {
      title: "Art Education",
      fileName: "../data/markdown/arts_education.md",
    },
  ];
  
  return (
    <div id="about-section" className="about-container">
      <div className="about-title">About</div>
      <ProfileCard />
      <SoftwareCard />
      <div className="skill-card-container">
        {skillsData.map((skill, index) => (
          <SkillCard
            key={index}
            title={skill.title}
            fileName={skill.fileName}
          />
        ))}
      </div>
      <FadeBackground backgroundClass='fade-background' imageFile="../images/black&white_city_bk1.png" altText="about background" />
    </div>
  );
}
export default About;
