
function Footer(){

    return(
        <div className="footer-container">
        <div className="footer-contact">Email</div>
        <div className="footer-social"> twitter linkedin</div>
        
        </div>
    )
}
export default Footer