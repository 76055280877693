
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



// Import your component files here
import NavBar from './components/NavBar';
import Home from './components/Home';
import About from './components/About';


function App() {
 

  return (
    <Router>
      <div id="home-section" className="container">
       
        <NavBar />
        <Routes>
        <Route path="/" element={<Home />} /> 
          <Route path="/about" element={<About />} /> 
          <Route path="/project" element={<Home />} /> 
        </Routes>
      </div>
    </Router>
  );
}
export default App;

