import React, { useEffect, useRef, useState } from "react";

function CurrentCard({ data, index }) {
  const [isVisible, setIsVisible] = useState(false);
  const [grayscale, setGrayscale] = useState(100);
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const ratio = entries[0].intersectionRatio;
        const newGrayscale = Math.round((1 - ratio) * 100);
        setGrayscale(newGrayscale);
        if (ratio > 0.1) {
          setIsVisible(true);
        }
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const animationClass = index % 2 === 0 ?  "slide-in-left" :"slide-in-right" ;

  return (
    <div
      ref={cardRef}
      className={`current-card ${isVisible ? animationClass : ""}`}
    >
      <div className="current-card-title">
        {data.name}
      </div>
      <div  style={{ filter: `grayscale(${grayscale}%)` }}>
        <img className="current-card-image" src={data.image} alt={data.name} />
      </div>
      <a className="current-card-link" href={data.link}>{data.link}</a>
      <div className="current-card-description">{data.description}</div>
    </div>
  );
}

export default CurrentCard;
